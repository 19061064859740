<!-- @format -->

<template>
	<DefaultLayout>
		<div
			style="
				display: none;
				-webkit-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
					rotateZ(0) skew(0, 0);
				-moz-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
					rotateZ(0) skew(0, 0);
				-ms-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
					rotateZ(0) skew(0, 0);
				transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
					rotateZ(0) skew(0, 0);
			"
			class="mobile--menu"
		>
			<div class="w-layout-hflex header--wrapper">
				<a href="#home" aria-current="page" class="brand w-inline-block w--current">
					<div class="sign--wrapper">
						<div class="toggle--wrapper">
							<img
								src="@/assets/img/swipez--toggle--shape.svg"
								loading="lazy"
								style="
									-webkit-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									-moz-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									-ms-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
								"
								alt=""
								class="sign--toggle"
							/>
						</div>
						<img
							src="@/assets/img/swipez--sqaure--shape.svg"
							loading="lazy"
							style="
								-webkit-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
								transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							alt=""
							class="sign--square"
						/>
					</div>
					<div class="w-layout-hflex swipez--text--wrapper">
						<img
							src="@/assets/img/Swipez--text--logo.svg"
							loading="lazy"
							style="
								-webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							alt="Swipez logotype"
							class="swipez--text--logo"
						/>
					</div>
				</a>
				<a
					aria-label="Close menu button"
					data-w-id="8976391a-d210-976d-86a3-3787b3af7718"
					href="#"
					class="menu--button w-inline-block"
				>
					<div class="hamb--line is--close-1"></div>
					<div class="hamb--line is--close--2"></div>
				</a>
			</div>
			<div class="nav is--mobile--menu">
				<a
					data-w-id="8976391a-d210-976d-86a3-3787b3af7702"
					href="#account"
					class="nav--link w-inline-block"
				>
					<div>{{ $t("Account") }}</div>
				</a>
				<a
					data-w-id="8976391a-d210-976d-86a3-3787b3af7705"
					href="#features"
					class="nav--link w-inline-block"
				>
					<div>Features</div>
				</a>
				<a
					data-w-id="8976391a-d210-976d-86a3-3787b3af7708"
					href="#pricing"
					class="nav--link w-inline-block"
				>
					<div>Pricing</div>
				</a>
				<a
					data-w-id="8976391a-d210-976d-86a3-3787b3af770b"
					href="#faq"
					class="nav--link w-inline-block"
				>
					<div>FAQ</div>
				</a>
				<a
					data-w-id="8976391a-d210-976d-86a3-3787b3af770e"
					href="#contact"
					class="nav--link w-inline-block"
				>
					<div>Contact</div>
				</a>
			</div>
			<div class="cta--wrapper is--mobile--menu">
				<a
					:href="adminUrl + 'login'"
					class="button is-secondary is--mobile--menu w-inline-block"
				>
					<div>Log In</div>
				</a>
				<a :href="adminUrl + 'signup'" class="button is--mobile--menu w-inline-block">
					<div>Sign Up</div>
				</a>
			</div>
		</div>
		<div class="w-layout-hflex header is--dark">
			<div class="w-layout-hflex header--wrapper">
				<a href="#home" aria-current="page" class="brand w-inline-block w--current">
					<div class="sign--wrapper">
						<div class="toggle--wrapper">
							<img
								src="@/assets/img/swipez--toggle--shape.svg"
								loading="lazy"
								style="
									-webkit-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									-moz-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									-ms-transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
									transform: translate3d(-250%, 0, 0) scale3d(1, 1, 1) rotateX(0)
										rotateY(0) rotateZ(0) skew(0, 0);
								"
								alt=""
								class="sign--toggle"
							/>
						</div>
						<img
							src="@/assets/img/swipez--sqaure--shape.svg"
							loading="lazy"
							style="
								-webkit-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
								transform: translate3d(0, 0, 0) scale3d(0, 0, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							alt=""
							class="sign--square"
						/>
					</div>
					<div class="w-layout-hflex swipez--text--wrapper">
						<img
							src="@/assets/img/Swipez--text--logo.svg"
							loading="lazy"
							style="
								-webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							alt="Swipez logotype"
							class="swipez--text--logo"
						/>
					</div>
				</a>
				<div class="nav">
					<a href="#account" class="nav--link w-inline-block">
						<div>Account</div>
					</a>
					<a href="#features" class="nav--link w-inline-block">
						<div>Features</div>
					</a>
					<a href="#pricing" class="nav--link w-inline-block">
						<div>Pricing</div>
					</a>
					<a href="#faq" class="nav--link w-inline-block">
						<div>FAQ</div>
					</a>
					<a href="#contact" class="nav--link w-inline-block">
						<div>Contact</div>
					</a>
				</div>
				<div class="cta--wrapper">
					<a :href="adminUrl + 'login'" class="button is-secondary w-inline-block">
						<div>Log In</div>
					</a>
					<a :href="adminUrl + 'signup'" class="button w-inline-block">
						<div>Sign Up</div>
					</a>
				</div>
				<a
					aria-label="Menu button"
					data-w-id="a8c6efb2-b7f6-afdb-7057-876b412a11f0"
					href="#"
					class="menu--button w-inline-block"
				>
					<div class="hamb--line"></div>
					<div class="hamb--line"></div>
				</a>
			</div>
			<div
				style="
					-webkit-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0)
						rotateY(0) rotateZ(0) skew(0, 0);
					-moz-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
						rotateZ(0) skew(0, 0);
					-ms-transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
						rotateZ(0) skew(0, 0);
					transform: translate3d(0, -100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
						rotateZ(0) skew(0, 0);
					display: none;
				"
				class="header--overlay"
			></div>
		</div>
		<div data-w-id="72fa98d1-19f4-83d1-4385-6e838d8fa27b" class="hero" id="home">
			<div
				data-w-id="a0d3df64-731a-5edf-5c16-4707d32675b2"
				class="hero--main--overlay"
			></div>
			<div class="hero--title--wrapper">
				<h1 class="h">
					<div class="hero-h-wrapper">
						<div
							data-w-id="a57a7ba6-1456-df65-733e-c340ef917366"
							style="
								-webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							class="h--super-large"
						>
							UNITED
						</div>
					</div>
					<div class="hero-h-wrapper">
						<div
							data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159"
							style="
								-webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								-ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
									rotateY(0) rotateZ(0) skew(0, 0);
								transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
									rotateZ(0) skew(0, 0);
							"
							class="h--super-large"
						>
							BANKING
						</div>
					</div>
				</h1>
				<p
					data-w-id="5bacc890-ffa5-cfa4-9f00-0ab015fc6e2f"
					style="
						-webkit-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0)
							rotateY(0) rotateZ(0) skew(0, 0);
						-moz-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
							rotateZ(0) skew(0, 0);
						-ms-transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
							rotateZ(0) skew(0, 0);
						transform: translate3d(0, 100%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
							rotateZ(0) skew(0, 0);
					"
					class="p--large"
				>
					Connect your partners into unified ecosystem <br />for rapid and secure fund
					flows
				</p>
			</div>
			<div class="w-layout-hflex more--wrapper">
				<a aria-label="Read more button" href="#account" class="more w-inline-block"
					><img
						src="@/assets/img/arrow--more.svg"
						loading="lazy"
						alt=""
						class="more--icon"
				/></a>
			</div>
			<div class="hero--img--wrapper">
				<div class="gradient--bottom"></div>
				<div class="gradient--top"></div>
				<div class="hero--img-overlay"></div>
				<div
					data-w-id="4fef72d0-1401-f900-0d7b-69a1c139b116"
					style="opacity: 0; display: none"
					class="hero--img"
					data-animation-type="spline"
					data-spline-url="https://prod.spline.design/IYaDkgY9wzTQCd-a/scene.splinecode"
				>
					<canvas></canvas>
				</div>
			</div>
		</div>
		<div class="account">
			<div id="account" class="anchor"></div>
			<div class="w-layout-hflex global--padding">
				<div class="global--wrapper">
					<div class="block--h">
						<div class="h-line">
							<div class="inner--line--black"></div>
						</div>
						<div class="text--chip">Account</div>
						<div class="h-line">
							<div class="inner--line--black"></div>
						</div>
					</div>
					<div class="hero--title--wrapper">
						<h2 class="h--large">PERSONAL <br />AND BUSINESS <br />ACCOUNT</h2>
						<p class="p--large is--black">
							Manage all your financial transactions, <br />make and receive
							payments.All-in-one space.
						</p>
						<a :href="adminUrl + 'signup'" class="button is--black w-inline-block">
							<div>Create Account</div>
						</a>
					</div>
					<div class="columns--wrapper">
						<div class="w-layout-hflex row--wrapper">
							<div class="service--item">
								<img src="@/assets/img/fast--icon.svg" loading="lazy" alt="" />
								<div class="w-layout-vflex flex-block-6">
									<h2 class="h--large is--black">FAST</h2>
									<h3 class="h--medium">Fast online application and onboarding</h3>
								</div>
							</div>
							<div class="v-line">
								<div class="inner--line--black-v"></div>
							</div>
							<div class="service--item">
								<img src="@/assets/img/light--icon.svg" loading="lazy" alt="" />
								<div class="w-layout-vflex flex-block-6">
									<h2 class="h--large is--black">SIMPLE</h2>
									<h3 class="h--medium">Execute all banking tasks at lightspeed</h3>
								</div>
							</div>
						</div>
						<div class="h-line">
							<div class="inner--line--black"></div>
						</div>
						<div class="w-layout-hflex row--wrapper">
							<div class="service--item">
								<img src="@/assets/img/planet--icon.svg" loading="lazy" alt="" />
								<div class="w-layout-vflex flex-block-6">
									<h2 class="h--large is--black">GLOBAL</h2>
									<h3 class="h--medium">
										SEPA &amp; SWIFT for easy fund transfers globally
									</h3>
								</div>
							</div>
							<!-- <div class="v-line">
								<div class="inner--line--black-v"></div>
							</div>
							<div class="service--item">
								<img src="@/assets/img/crypto--icon.svg" loading="lazy" alt="" />
								<div class="w-layout-vflex flex-block-6">
									<h2 class="h--large is--black">MODERN</h2>
									<h3 class="h--medium">Crypto payments and investments</h3>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="features">
			<div id="features" class="anchor"></div>
			<div class="w-layout-hflex global--padding">
				<div class="global--wrapper wide">
					<div class="block--h">
						<div class="h-line is--white">
							<div class="inner--line--right"></div>
						</div>
						<div class="text--chip is--yellow">FEATURES</div>
						<div class="h-line is--white">
							<div class="inner--line--right"></div>
						</div>
					</div>
					<h2 class="h--large is--white is--center">
						UNITY AT<br />
						THE CORE
					</h2>
					<div class="v-sep">
						<div class="inner--line--white"></div>
						<div class="w-layout-hflex flex-block-3">
							<div class="inner--line--white"></div>
						</div>
					</div>
					<div class="feature--item">
						<div class="w-layout-hflex flex-block-2">
							<div class="div-block-3">
								<div class="text-block">01</div>
							</div>
							<h2 class="h--large is--white">TOP-TIER<br />SECURITY</h2>
						</div>
						<p class="p--medium is--feature-p">
							Trust in our state-of-the-art security protocols designed to provide peace
							of mind, so you can focus on managing your finances with confidence.
						</p>
					</div>
					<div class="v-sep">
						<div class="inner--line--white"></div>
						<div class="w-layout-hflex flex-block-3">
							<div class="inner--line--white"></div>
						</div>
					</div>
					<div class="feature--item">
						<div class="w-layout-hflex flex-block-2">
							<div class="div-block-3">
								<div class="text-block">02</div>
							</div>
							<h2 class="h--large is--white">SMOOTH<br />TRANSACTIONS</h2>
						</div>
						<p class="p--medium is--feature-p">
							Experience seamless and efficient transactions like never before. Our
							platform ensures every payment and banking task is processed swiftly,
							securely, and without hassle.
						</p>
					</div>
					<div class="v-sep">
						<div class="inner--line--white"></div>
						<div class="w-layout-hflex flex-block-3">
							<div class="inner--line--white"></div>
						</div>
					</div>
					<div class="feature--item">
						<div class="w-layout-hflex flex-block-2">
							<div class="div-block-3">
								<div class="text-block">03</div>
							</div>
							<h2 class="h--large is--white">INTEGRATED <br />ECOSYSTEM</h2>
						</div>
						<p class="p--medium is--feature-p">
							Our platform connects all your partners, employees, and contractors in one
							unified hub. Experience seamless collaboration and streamlined communication
							across your entire network.
						</p>
					</div>
					<div
						data-w-id="aaba2b3f-e028-3849-b9cd-2712fbcd6113"
						class="feature--item--block"
					>
						<div class="div-block-3">
							<div class="text-block">EXPLORE</div>
						</div>
						<h2 class="h--large is--white v--2">
							complete financial platform powered <br />by bank account
						</h2>
						<a
							:href="adminUrl+'signup'"
							class="button w-inline-block"
						>
							<div>Create Account</div>
						</a>
						<div class="div-block-4"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="pricing">
			<div id="pricing" class="anchor"></div>
			<div class="w-layout-hflex global--padding">
				<div class="global--wrapper">
					<div class="block--h">
						<div class="h-line">
							<div class="inner--line--black"></div>
						</div>
						<div class="text--chip">PRICING</div>
						<div class="h-line">
							<div class="inner--line--black"></div>
						</div>
					</div>
					<div class="pricing--wrapper">
						<h2 class="h--large">Explore <br />our pricing</h2>
						<a href="#contact" class="button is--black w-inline-block">
							<div>Contact Sales</div>
						</a>
					</div>
					<div
						data-current="Business"
						data-easing="ease"
						data-duration-in="300"
						data-duration-out="100"
						class="tabs w-tabs"
					>
						<div class="tabs-menu w-tab-menu">
							<a data-w-tab="Business" class="tab w-inline-block w-tab-link w--current">
								<div>BUSINESS</div>
							</a>
							<a data-w-tab="Private Individuals" class="tab w-inline-block w-tab-link">
								<div>Private Individuals</div>
							</a>
							<a data-w-tab="Personal" class="tab w-inline-block w-tab-link">
								<div>Personal</div>
							</a>
						</div>
						<div class="tabs-content w-tab-content">
							<div
								data-w-tab="Business"
								class="tab-pane-personal w-tab-pane w--tab-active"
							>
								<div class="pricing--table--wrapper">
									<div class="w-layout-hflex price--item">
										<div>Handling of documents and opening a customer account</div>
										<div>2000 EUR</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Account monthly service fee</div>
										<div>100 EUR</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming internal payment</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SEPA payment</div>
										<div>0.5%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SWIFT payment</div>
										<div>0.7%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>
											Internal outgoing payment between own accounts within Swipez
										</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Internal outgoing payment within Swipez</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SEPA payment</div>
										<div>0,1%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SWIFT payment</div>
										<div>0,3%</div>
									</div>
								</div>
							</div>
							<div data-w-tab="Private Individuals" class="w-tab-pane">
								<div class="pricing--table--wrapper">
									<div class="w-layout-hflex price--item">
										<div>Handling of documents and opening a customer account</div>
										<div>1000 EUR</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Account monthly service fee</div>
										<div>75 EUR</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming internal payment</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SEPA payment</div>
										<div>0.5%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SWIFT payment</div>
										<div>0.7%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>
											Internal outgoing payment between own accounts within Swipez
										</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Internal outgoing payment within Swipez</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SEPA payment</div>
										<div>0,1%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SWIFT payment</div>
										<div>0,3%</div>
									</div>
								</div>
							</div>
							<div data-w-tab="Personal" class="w-tab-pane">
								<div class="pricing--table--wrapper">
									<div class="w-layout-hflex price--item">
										<div>Opening a customer account</div>
										<div>Free of charge</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Account monthly service</div>
										<div>Free of charge</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming internal payment</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SEPA payment</div>
										<div>Free of charge</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Incoming SWIFT payment</div>
										<div>10 USD</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>
											Payment card top-up
										</div>
										<div>1,5%</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Internal outgoing payment</div>
										<div>Free of change</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SEPA payment</div>
										<div>5 EUR</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Outgoing SWIFT payment</div>
										<div>20 USD</div>
									</div>
									<div class="w-layout-hflex price--item">
										<div>Withdrawal to card</div>
										<div>1,5%</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="faq">
			<div id="faq" class="anchor"></div>
			<div class="w-layout-hflex global--padding">
				<div class="global--wrapper">
					<div class="block--h">
						<div class="h-line is--white">
							<div class="inner--line--right"></div>
						</div>
						<div class="text--chip is--yellow">FAQ</div>
						<div class="h-line is--white">
							<div class="inner--line--right"></div>
						</div>
					</div>
					<h2 class="h--large is--white is--center">FREQUENTLY<br />ASKED QUESTIONS</h2>
					<div class="faq--wrapper">
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="760e5155-fb16-35d5-58ca-9cd55997e12d"
								class="w-layout-hflex ac--header"
							>
								<div>What country is Swipez registered at?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										Swipez company is registered in the Czech Republic.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="419f7d68-2ad8-a547-6c62-027e884bd110"
								class="w-layout-hflex ac--header"
							>
								<div>How safe is Swipez service?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										We use technologies that guarantee secure online payments and
										two-factor authentication. Moreover, users receive real time
										notifications about all transactions on the account.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="74d9ef66-4bfe-eb9d-5c86-5c593910a767"
								class="w-layout-hflex ac--header"
							>
								<div>How safe is it to keep money at Swipez?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										Funds of our customers are kept in a separate account in the Czech
										bank and can be used by no one, except for the customer, i.e. they are
										protected 100%.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="b6314e4c-3ffa-56ed-4396-96b5460f593a"
								class="w-layout-hflex ac--header"
							>
								<div>What documents must be presented for opening an account?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										When opening an account, a passport or ID card is required, as well as
										utility bill or a bank statement on the account with your name and address.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="181dfd99-99df-ecc6-8367-0416fe0b12cd"
								class="w-layout-hflex ac--header"
							>
								<div>When will I get access to the online bank?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										You will get access as soon as your identity is checked and verified.
										Usually, it takes from 30 minutes to several hours.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
						<div class="faq--item">
							<div
								data-w-id="9407adf3-6b2d-8a5d-d546-f805cb02f035"
								class="w-layout-hflex ac--header"
							>
								<div>Residents of what countries may not open an account?</div>
								<div class="open--acc--button">
									<div class="ac--line--2"></div>
									<div class="ac--line--1"></div>
								</div>
							</div>
							<div
								style="width: 100%; height: 0px; display: none"
								class="w-layout-hflex ac--content"
							>
								<div class="w-layout-hflex flex-block-4">
									<div class="p--medium">
										Currently, an account may be opened, if you are over 18 and you are
										not a resident of the following countries: Afghanistan, Albania,
										Algeria, Central African Republic, China, Cuba, Democratic Republic of
										Congo, Ecuador, Guyana, Indonesia, India, Iran, Iraq, Ivory Coast,
										Nigeria, North Korea, Laos, Panama, Papua New Guinea, Russia, Sudan, Syria,
										Uganda, Yemen, Zimbabwe.
									</div>
								</div>
							</div>
						</div>
						<div class="v-sep">
							<div class="inner--line--white"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</DefaultLayout>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { DefaultLayout } from "@/layout";
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
import HomeAdapterService from "./adapter/HomeAdapterService";
import Footer from "@/layout/Footer.vue";

let adminUrl =  ref("")
onMounted(async () => {
	loadSettings() 
});
async function loadSettings() {
	try {
		// Запрашиваем файл appWebSettings.json из папки public
		const response = await fetch("/appWebSettings.json");

		// Проверяем, что запрос успешен
		if (!response.ok) {
			throw new Error("Не удалось загрузить настройки");
		}

		// Преобразуем ответ в JSON
		const settings = await response.json();

		// Сохраняем нужные параметры в переменные
		adminUrl.value = settings.adminUrl;
	} catch (error) {

		console.error("Ошибка при загрузке настроек:", error);
	}
}
</script>

<style lang="scss"></style>

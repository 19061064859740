<template>
<DocumentLayout>
	<h1 class="h1--hero">
		<div class="hero-h-wrapper">
			<div data-w-id="a57a7ba6-1456-df65-733e-c340ef917366" class="h--super-large">
				TERMS
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159" class="h--super-large">
				AND 
			</div>
		</div>
		<div class="hero-h-wrapper">
			<div data-w-id="201b457d-0993-bca7-76e5-88cdccd4a159" class="h--super-large">
				CONDITIONS
			</div>
		</div>
	</h1>
	<h2><span><strong>1. ABOUT THE DRAPH</strong></span></h2>
	<p>Draph Solutions S.r.o. is a company incorporated in the Czech Republic (&lsquo;CZ&rsquo;) with the registered company number C 278807, whose registered office address is at P&yacute;chavkov&aacute; 264/2, Pitkovice, 104 00, Praha (the &lsquo;Draph&rsquo;, &lsquo;we&rsquo;, or &lsquo;us&rsquo;).</p>
	<p>We are offering payment services as a small-scale payment service provider (the &lsquo;SSP&rsquo;) and not offering banking services.&nbsp;</p>
	<p>We are authorised and regulated by the Czech National Bank (the &lsquo;CNB&rsquo;).</p>
	<p>We are recognized and provide our services under the trade name &lsquo;<strong>Swipez</strong>&rsquo;.</p>
	<p>WE PROVIDE NO FINANCIAL, INVESTMENT, LEGAL OR OTHER KIND OF ADVICES REGARDING MANAGEMENT AND USE OF CLIENT&rsquo;S FUNDS AND SHALL NOT PROVIDE YOU WITH POSSIBLE RISKS ASSESSMENT WHILE USING THE SERVICES. WE MAY ONLY CONSULT THE CLIENT ON PROVISIONS OF THE TERMS OF SERVICES INTENDED TO BE USED OR ALREADY USED BY THE CLIENT, SYSTEM USE AND TECHNICAL OPTIONS, AS WELL AS, CONCERNING CLIENTS&rsquo; PERSONAL DATA PROCESSING AND PROTECTION, CONSUMERS&rsquo; RIGHTS COMPLIANCE AND PROTECTION, MANDATORY BINDING LAWS AND REGULATIONS IN CASE OF COMPLAINTS AND DISPUTES. THE CLIENT, PRIOR ENTERING BUSINESS RELATIONSHIP AND STARTING USE OF THE SERVICES, SHALL ASSESS POSSIBLE RISKS AND ITS SATISFACTION WITH THE SERVICES INTENDED TO BE USED.</p>
	<p>IN NO WAY WE WILL INTERVENE IN THE LEGAL AND COMMERCIAL RELATIONSHIPS AND ANY LITIGATION ARISING BETWEEN THE RECIPIENT AND THE CLIENT, PAYMENT SERVICE PROVIDER/PAYMENT INSTITUTION. IT IS UP TO THE CLIENT TO OBTAIN ALL USEFUL INFORMATION BEFORE PROCEEDING TO THE PURCHASE OF A PRODUCT OR SERVICE, THE COLLECTION OF FUNDS OR ANY OTHER TRANSACTION, IN FULL KNOWLEDGE OF ALL THE CONSIDERATIONS INVOLVED. ANY TRANSACTION CARRIED OUT BY THE CLIENT GIVES RISE TO A CONTRACT DIRECTLY FORMED BETWEEN THE CLIENT AND THE THIRD PARTY WITH WHOM THE DRAPH HAS NO ANY RELATIONSHIP OR OBLIGATIONS. THE DRAPH CANNOT, UNDER ANY CIRCUMSTANCES, BE HELD LIABLE FOR THE NON-PERFORMANCE OR POOR EXECUTION OF OBLIGATIONS THAT MAY RESULT, OR POSSIBLE DAMAGES CAUSED TO THE CLIENT IN THIS RESPECT.</p>
	<p>YOU ACKNOWLEDGE AND AGREE THAT DRAPH WILL NOT BE RESPONSIBLE TO YOU FOR ANY LOSS OR DAMAGE YOU SUFFER IN CONNECTION WITH THE USE OF THE SERVICES WHERE SUCH LOSS OR DAMAGE IS CAUSED BY YOUR ACT OR OMISSION OR BY THOSE OF A THIRD PARTY.</p>
	<p>IF DRAPH FAILS TO COMPLY WITH THE TERMS, ANY SUPPLEMENT HERETO OR ANY DOCUMENT WE REFER TO IN THEM WE WILL BE RESPONSIBLE FOR ANY DIRECT LOSS OR DAMAGE YOU SUFFER THAT IS A FORESEEABLE RESULT OF DRAPH BREACHING ITS CONTRACTUAL OBLIGATION OR FAILING TO USE REASONABLE CARE AND SKILL WHEN PROVIDING ITS SERVICES UNDER THE TERMS, ANY SUPPLEMENT HERETO OR ANY DOCUMENT WE REFER TO IN THEM, BUT DRAPH IS NOT RESPONSIBLE FOR ANY LOSS OR DAMAGE THAT IS NOT FORESEEABLE OR WHICH IS NOT CAUSED BY ITS FAILURE OR FAULT.</p>
	<p>DRAPH TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW WILL NOT BE LIABLE FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS OPPORTUNITY, AS WELL AS, WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH BUSINESS RELATIONSHIP.</p>
	<p>&nbsp;</p>
	<h2><span><strong>2. THE SCOPE OF THE TERMS</strong></span></h2>
	<p>These Terms of Service (the &lsquo;Terms&rsquo;) and each of the documents we refer to in them, are our standard client agreement, which creates a legally binding agreement between the client and the Draph relating to the provision of payment services.</p>
	<p>These Terms are prepared and shall be governed by and interpreted in accordance with the laws of the Czech Republic. These Terms state the basic rights and obligations of the business relationship established between the Draph and its clients.</p>
	<p>These Terms set out the conditions under which we provide payment services to our clients, as well as, conditions, upon which the account is opened and maintained.</p>
	<p>By this we inform you that your funds held with the Draph are not insured and protected by the Czech governmental compensation schemes to cover losses claimed in connection with the Services provided and funds held on the accounts. Nevertheless, we ensure that your funds held with us are covered and protected by appropriate and secure internal safeguarding procedures established by the Draph and are safeguarded in accordance with the requirements of the EU Directive 2015/2366, of the Parliament and of the Council, of 25 November 2015. In case of our insolvency, clients&rsquo; funds will be protected against any claims of any creditors of the Draph, as they are held on a safeguarded account. More detailed information on tools and methods we use to safeguard your funds is provided in the Safeguarding Disclaimer available on the Website.</p>
	<p>You are kindly advised to read these Terms and each of the documents we refer to in them carefully before accepting them. For Your own benefit and the protection of your legitimate interests, please refer to the Draph before accepting these Terms if any provision contained herein or in any of the documents, we refer to in them, appears to be unclear, doubtable or unfair. You may easily contact us at our registered office address specified above herein or via email address <a href="mailto:support@swipez.eu"><span style="color: #0000ff;"><u>support@swipez.eu</u></span></a>.</p>
	<p>If you have proceeded with the payment account opening and registering in the Draph online system to become a client of us, it is considered, that you have carefully read the Terms, any supplement hereto and any other document we refer to in them and it shall be deemed that you agree to the provisions contained in these Terms, any supplement hereto and each of the document we refer to in them, thus confirming that all provisions set forth herein are clear and fully understandable to you, undoubtable and fair, and you fully agree to them.</p>
	<p>While using our services you undertake to:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>comply with the Terms</span>, <span>any supplements hereto or any document we refer to in them, applicable national and supra-national laws and regulations we refer to,</span></p>
		</li>
		<li>
			<p><span>not to provide false, misleading or incorrect information to us, not to refuse to provide us with information and to take other actions that are reasonably requested by us,</span></p>
		</li>
		<li>
			<p><span>pass through all compulsory procedures carried out by us for the purposes of the </span>c<span>lient&rsquo;s identity verification and KYC, to submit all requested data and information,</span></p>
		</li>
		<li>
			<p><span>inform us in a timely manner on any changes to the information, data and documents previously submitted. In case you fail to do so, we are released from any liability regarding correct and accurate execution of payment transaction and you shall bear full responsibility and bear all and any losses, also in relation to any third party, that may occur due to submission of invalid data or refusal to submit the data. We retain the right to suspend the provision of services till you update data and information,</span></p>
		</li>
		<li>
			<p><span>not to use funds of illegal origin for services provided,</span></p>
		</li>
		<li>
			<p><span>not to use services in a way which causes losses or other negative legal or financial consequences or damage to the business stability and reputation of Draph or any third person,</span></p>
		</li>

		<li>
			<p><span>no to log in to the </span>Draft<span> system as an anonymous user (e.g., via proxy servers) or to have more than one profile in the system; not to register a profile by fictitious or third person&rsquo;s name without having respective representative legal ground; not to register a profile using the services of anonymous phone numbers or e-mail addresses provided by other individuals or websites,</span></p>
		</li>
		<li>
			<p><span>not to use the account for services or goods that are prohibited by the laws or contradict public order and moral principles,</span></p>
		</li>
		<li>
			<p><span>knowingly not to provide to third parties false, misleading, or incorrect information about us and services we provide,</span></p>
		</li>
		<li>
			<p><span>observe and respect Draph and third party&rsquo;s trademarks/trade names, copyrights, commercial secrets, and other intellectual property rights. This clause shall not apply to you in so far, as the you, being in good faith, did know and couldn&rsquo;t know on the existence of trademarks/trade names, copyrights or other intellectual property rights, or that information constitutes a commercial secret,</span></p>
		</li>
		<li>
			<p><span>not to spread computer viruses and not to undertake other actions that could cause our system&rsquo;s malfunctions, information damage or destruction and other damage to the system, Draph equipment, or information,</span></p>
		</li>
		<li>
			<p><span>to reimburse in full and keep us harmless against any costs and losses which may arise if you fail (in full or partially, including, but not limited to, omission or misleading) to perform the obligations under the Terms or such costs or losses have arisen due to your unlawful actions.</span></p>
		</li>
	</ol>

	<h2><span><strong>3.DEFINITIONS</strong></span></h2>

	<p><strong>Account </strong>- account or multi-currency account (if available) opened in the name of the Client and used for the Services provided by the Draph.</p>
	<p><strong>Account Balance</strong> - amount of funds held in the Account (s).</p>
	<p><strong>Act</strong> - Personal Data Processing Act 2019 (No. 110/2019 Coll., &lsquo;ZZO&Uacute;&rsquo;).</p>
	<p><strong>AML/CTF</strong> - Anti-Money Laundering and Counter Terrorism Financing.</p>
	<p><strong>App - </strong>mobile application software of Draph System.</p>
	<p><strong>Application</strong> &ndash; paper or on-line initial form for Account opening, which should be filled in by the Client or its Representative.</p>
	<p><strong>Business Account </strong>- account opened for a legal entity or private individual for his/her commercial purposes.</p>
	<p><strong>Business Day </strong>- any calendar day, apart from Saturday, Sunday and public holidays in the Czech Republic, on which the payment infrastructures and the banks are open for business in the Czech Republic, or any other day defined by us and notified to the Client in a duly manner.</p>
	<p><strong>Business Hours&nbsp;</strong>&ndash; business hours from 09:00 am to 17:30 pm (CZ time zone).</p>
	<p><strong>Business Relationship</strong> - mutually binding relationship between the Draph and the Client arose from using the Services.</p>
	<p><strong>Client (also &lsquo;you&rsquo;) </strong>- legal entity or private individual on which/whose name the Account is opened and Services are used.</p>
	<p><strong>Client&rsquo;s Identification </strong>- set of KYC and Due Diligence measures applied by the Draph to prevent money laundering and terrorism financing subject to mandatory binding requirements of applicable AML/CTF regulations.</p>
	<p><strong>Fees</strong> <strong>(also fees)</strong> - charges payable by the Client for the Services provided as set in the Fee schedule. The Fee schedule is available in the System.</p>
	<p><strong>GDPR </strong>- Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</p>
	<p><strong>KYC</strong> - Know Your Client procedures undertaken by the Draph under the mandatory provisions of the applicable AML/CTF laws and regulations.</p>
	<p><strong>Order</strong>&nbsp;- instruction given by the Client to execute a Payment Transaction.</p>
	<p><strong>Party or Parties</strong> - you and us.</p>
	<p><strong>Payer </strong>- private individual or legal entity, who/which executes a payment order from a payment account.</p>
	<p><strong>Payment Transaction </strong>- funds transfer initiated by the Payer, on Payer&rsquo;s name, or by the Recipient.</p>
	<p><strong>Personal Account </strong>- account opened for private individuals for non-commercial purposes.</p>
	<p><strong>Personal Data </strong>- any information that relates to the&nbsp;identified or identifiable private individual, also different pieces of information, which collected together can lead to the identification of a particular person. Personal Data processing principles are defined in the Draft Privacy Policy available on the Website.</p>
	<p><strong>Privacy Policy</strong> - Draph internal privacy rules and procedures regarding Personal Data processing, including further Personal Data transfer and storage, established based on the Act and the GDPR, as well as, common good practice standards.</p>
	<p><strong>Profile</strong> - Client&rsquo;s personal panel in the System available via the Website or App, where the Client may manage the Account(s), use Services, place Orders and execute Payment Transactions.</p>
	<p><strong>Recipient </strong>- private individual or legal entity specified in the Order as a recipient of funds of the Payment Transaction.</p>
	<p><strong>Representative</strong> - person duly authorised by the Client to represent the Client in relations with the Draph, use the Services, place Orders and execute Payment Transactions, acting under a power of attorney or other legal ground.</p>
	<p><strong>Services </strong>- Payment Transactions executed by means of the Account (s).</p>
	<p><strong>Strong Customer Authentication (SCA) </strong>- verification method based on at least two elements categorised in the Directive (EU) 2015/2366 of the European Parliament and the Council on payment services in the internal market, amending Directives 2002/65/EC, 2009/110/EC and 2013/36/EU and Regulation (EU) No 1093/2010, and repealing Directive 2007/64/EC (25 November 2015).</p>
	<p><strong>System </strong>- software solution used by the Draph for Account(s) maintenance and on-line provision of the Services. The System may be used by means of the Website or App.</p>
	<p><span><strong>Unauthorised Access </strong></span><span>- access to the Account or Profile without the Client&rsquo;s authorization or permission, fraudulently, wrongfully or</span> <span>otherwise criminally gained by a third person (s).</span></p>
	<p><span><strong>Unauthorized Order</strong></span><span> &ndash; Order for Payment Transaction execution that was not authorized by the Client.</span></p>
	<p><strong>Website</strong> &ndash; <a href="http://www.swipez.eu"><span style="color: #0000ff;"><u>www.swipez.eu</u></span></a>.</p>
	<ol start="4">
		<li>
			<h2><span><strong>ACCOUNT OPENING </strong></span></h2>
		</li>
	</ol>
	<p>We open the Account for an indefinite period of time.</p>
	<p>To open the Account and use our Services you shall fill in and submit the Application.</p>
	<p>The Application might be completed in a paper form and submitted in person if the Client applies for the Draph Services in person.</p>
	<p>The Client may fill in the Application electronically and submit via the System if the Client applies for the Draph Services remotely.</p>
	<p>Only the Client over the age of 18 (eighteen) years old and having the capacity to enter into a legally binding relationship may apply and use our Services.</p>
	<p>You will not be eligible to open an Account, if you are a resident, a citizen or a legal entity incorporated or doing business in the countries, which appear in the list of prohibited countries recognized as such by the Draph.</p>
	<p>To register with the System, the SCA should apply. Since the Application is submitted, you will receive a verification code to your phone number and a verification link to the e-mail address both specified by you in the submitted Application. You need to confirm the verification link by clicking it and entering the verification code sent to the Client&rsquo;s phone number. If the entered code is genuine and the link is successfully confirmed, a unique System entering password will be generated and sent to your e-mail address. E-mail address specified in the Application shall be used as access login to enter the System.</p>
	<p>Usually, personalized credentials to enter the System are used to access the App.</p>
	<p>You must ensure that your login and password are kept safe and prevent fraudulent use of them by third persons. You agree not to disclose your login and password to any third person and protect the secrecy of these data at all times. You are solely responsible for the use of your login and password (credentials) and maintaining the confidentiality of these data.</p>
	<p>You agree to immediately inform us in the event you suspect or get known of any unauthorized use of your login and password. You shall bear full responsibility and reimburse any and all losses that may occur due to access credentials unauthorised disclosure to a third person, till we have received respective notification from you.</p>
	<p>The Account may be opened only after the Client&rsquo;s identity is fully verified and all documents necessary for identity verification and KYC purposes are duly received and approved by the Draph.</p>
	<p>All information and documents the Client submits us must be complete, accurate and truthful. The Client shall always inform us and keep updated this information whenever it changes. We shall bear no responsibility for any financial loss arising out of the Client&rsquo;s failure to do so. We reserve the right to ask the Client at any time to confirm the accuracy of the information and documents already submitted to us and/or to provide additional information and supporting documents.</p>
	<p>Upon agreeing with the Terms, you hereby irrevocably authorize us to request any information and documents, regardless of its form, related to the Client&rsquo;s identity verification from you and/or any Registry, lawful substitute, credit institution or a financial institution, Notary Public, any Municipal or Governmental Authority, which has or had identified you and verified your identity, or has or had a business relation with you. This also applies to the Representative, directors and beneficial owners of the legal entity, any parties involved in the Payment Transaction, for example, Payer, Recipient, etc.</p>
	<p>In order to meet our regulatory obligations and perform KYC procedures, we may request to provide original documents or copies of documents certified by the person authorized by law. In separate cases we may request submitted documents to be apostilled and when necessary legalized.</p>
	<p>If the Client refuses to provide additional information or documents, we may decline the Application and not provide Services to the Client.</p>
	<p>We also retain the right to refuse the Application by giving no explanations in this regard, however, our refusal will have significant and valid reasons.</p>
	<p>If your Application is approved by the Draph and your identity is successfully verified, you may proceed with the System and create a Profile there.</p>
	<p>After the Profile is created, the Client shall proceed with the KYC questionnaire. Type of KYC questionnaire to be completed depends on Client&rsquo;s type &ndash; private individual or legal entity.</p>
	<p>KYC questionnaire must be completed to meet Draph mandatory AML/CTF regulatory obligations and get full understanding of who the client is, it&rsquo;s nature and type of business activity/occupation, purpose the Services are intended to be used, get full confidence whether origin of funds held with the Draph and used for Payment Transactions is lawful and funds are not derived from the illegal activity, whether the Draph is not servicing any sanctioned persons or entities, terrorists or persons being criminally prosecuted or wanted.</p>
	<p>This is exclusively your responsibility to provide us accurate, true and valid data and information, and you shall reimburse us any and all losses that may occur due to the submission of inaccurate or invalid data.</p>
	<p>If the Client applies for the Draph Services remotely, then a liveness check should be passed and selfie with the identity document shall be submitted. In this case you will receive a liveness verification link to your e-mail address registered with us. We will provide your clear instructions for liveness and selfie check.</p>
	<p>If Account is applied by the Representative, then Representative documents confirming the legal ground of his/her representation must be submitted, identity verification and KYC procedures as specified above herein shall be applied.</p>
	<p>In case the Account is opened for the benefit of a third person &ndash; beneficial owner, then this person is subject to identity verification and KYC checks applicable by the Draph to the private individuals.</p>
	<p>The Account shall be opened and the Services may be provided only when the Client, the Representative or the person for whose benefit the Account is opened (if any) is fully verified, all checks and mandatory screenings are successfully passed and Draph has full understanding of who the Client is.</p>
	<p>Under no circumstances the Draph opens the Account and provides the Services if:</p>
	<ul>
		<li>
			<p><span>the Client, the Representative or the person for whose benefit the Account is opened (if any) is not fully verified,</span></p>
		</li>
		<li>
			<p><span>positive matches against sanction lists, blocked and specially designated persons lists, wanted persons lists and etc. in respect of the Client, the Representative or the person for whose benefit the Account is opened (if any) are found and confirmed, </span></p>
		</li>
		<li>
			<p><span>negative adverse media surrounding the Client, the Representative or the person for whose benefit the Account is opened (if any) is found,</span></p>
		</li>
		<li>
			<p><span>Client, it&rsquo;s geographical ties (place of birth, incorporation, citizenship, residence, place of business etc.), nature and type of business activity or occupation falls under non-cooperative (prohibited) in accordance with the Draph established internal AML/CTF rules and procedures,</span></p>
		</li>
		<li>
			<p><span>it is identified that funds the Client intends to use for Payment Transactions are of illegal/criminal origin, or it is not possible to identify origin of such funds, </span></p>
		</li>
		<li>
			<p><span>cooperation with the Client may pose the Draph, </span>its<span> partners and vendors, and other Clients a significant reputational risk.</span></p>
		</li>
	</ul>
	<p>Under no circumstances anonymous Accounts are opened and anonymous Payment Transactions are executed.</p>
	<p>When opening the Account for the Client being a private individual, it shall be deemed that such Client is acting exclusively for non-commercial purposes, if reverse, a Business Account shall be opened.</p>
	<p>Funds held on the Account (s) do not earn any interest.</p>
	<p>The Client hereby commits to the Draph that the opening and usage of the Account (s) does not violate any laws applicable to the Client or Draph and the Client bears full responsibility if commitments provided herein turned out false and misleading.</p>
	<p>We reserve the right not to open an Account and/or close an Account without providing a reason, however, our decision will have significant and valid ground.</p>
	<ol start="5">
		<li>
			<h2><span><strong>USE OF THE ACCOUNT</strong></span></h2>
		</li>
	</ol>
	<p>The Account is personal to the Client, is opened in the name of the Client and the Client may not assign any rights under these Terms to any third party.&nbsp;</p>
	<p>All activities under the Account shall be deemed as activities carried out by the Client as registered user.</p>
	<p>The Client may open only one Account. Where duplicate Accounts are detected, we may close or merge these duplicate Accounts at our sole discretion.</p>
	<p>The Account enables the Client to execute SEPA and SWIFT payments, intra system payment, and convert currency.</p>
	<p>Multi-currency Account may be opened. Available currencies and currency exchange rates are available in the Profile. The currency is exchanged based on the currency exchange rates valid at the moment of conversion.</p>
	<p>Our Services include:</p>
	<ul>
		<li>
			<p><span>Payment Transactions from the Draph&rsquo;s Accounts to the bank accounts or other payment service providers&rsquo; accounts, either in the form of SWIFT/SEPA transfers and (or) local bank transfers where applicable and available,</span></p>
		</li>
		<li>
			<p><span>Payment Transactions to the Draph&rsquo;s Accounts from the bank accounts or other payment service providers&rsquo; accounts,</span></p>
		</li>
		<li>
			<p><span>currency exchange,</span></p>
		</li>
		<li>
			<p><span>internal Payment Transactions between the Draph Accounts.</span></p>
		</li>
	</ul>
	<p>Banks and payment service providers may apply their own fees for Payment Transactions execution. Draph is released from any liability or expenses reimbursement subject to the banks or payment service providers applicable fees.</p>
	<p>The Draph may set limits to the Accounts depending on the Money Laundering (&lsquo;ML&rsquo;) risk type assigned to the Client by the Draph. Limits are established and adjusted at the Draph&rsquo;s sole discretion.</p>
	<p>It is strictly prohibited to use the Account for the funds obtained as a result of criminal activity or use the Services/Account for any illegal purposes, including but not limited to, fraud, money laundering and/or terrorist financing. In such a case the Draph is entitled to immediately suspend provision of Services, block all operation on the Account (s), &lsquo;freeze&rsquo; the funds held on the Account (s) and report any suspicious activity to the relevant competent authority.</p>
	<p>All Payment Transactions of the Client (including Account Balance, funds that have been uploaded, received, sent, withdrawn or exchanged) are recorded in the operation history section in the Profile. The Client may access this information free of charge after logging in to the Profile.</p>
	<p>The Client agrees and accepts all the risks associated with maintaining the Account Balances in multiple currencies, including any risks associated with the fluctuations in the relevant exchange rates over the time. The Client undertakes not to use the Services for speculative trading.</p>
	<p>The Client undertakes to always have a zero or positive Account Balance. If a Client's Account goes into a negative balance as a result of a chargeback, reversal of a transaction, deduction of fees or any other action carried by the Client, the Client undertakes to repay the negative balance immediately without any notice from us.</p>
	<p>We reserve the right to take reasonable actions to recover the negative Account Balance from the Client, for example, to take further legal actions, use debt collection services and to charge Client for any costs and expenses the Draph may incur. The Client agrees to fully reimburse the Draph all such costs and expenses.</p>
	<ol start="6">
		<li>
			<h2><span><strong>THE ORDER</strong></span></h2>
		</li>
	</ol>
	<p>Each time the Client wants to make a Payment Transaction a respective Order should be completed and submitted to the Draph for further execution.</p>
	<p>The Order shall be completed in the Profile in accordance with the instructions given in the System. Details on the Payer or Recipient should be valid at the moment of the transfer.</p>
	<p>The Order should include the following components:</p>
	<ol type="i" class="litle">
		<li>
			<p><span> the Payment Transaction amount,</span></p>
		</li>
		<li>
			<p><span>the currency,</span></p>
		</li>
		<li>
			<p><span>the details required to identify the Recipient, </span></p>
		</li>
		<li>
			<p><span>the date of execution of the Order, and </span></p>
		</li>
		<li>
			<p><span>any other required information. </span></p>
		</li>
	</ol>
	<p>When deemed reasonably necessary before Order execution we may request Payment Transaction supporting information (including supporting documents). The Order will be executed only after the requested information is provided in a timely manner (information submission term will be notified to you at the moment of information request), in full and is at Draph&rsquo;s satisfaction. If requested information is not provided or is not provided timely, in full, or does not satisfy the Draph, the Order will be cancelled by us; in case of incoming Payment Transactions incoming funds will be returned to the Payer.</p>
	<p>To submit the Order, you should click the validation tab in the System and authorise the Order using your personal credentials. When the validation tab is clicked and the Order is authorised, the Order becomes irrevocable.</p>
	<p>The Payment Transaction will be executed, at the latest, <em>on the next Business Day</em> following the date when the Order is confirmed by you via the System. If Order&rsquo;s submission date is not a Business Day, it will be deemed that Order has been received <em>on the following Business Day</em>.</p>
	<p>Payment Transactions within the System are executed immediately after the Order is submitted, unless the transaction is suspended due to the provisions set forth hereof.</p>
	<p>If an incorrect Order is submitted, but the Payment Transfer has not been executed yet, the Client may request to correct the Order. Draph may apply a fee for the correction of the Order.</p>
	<p>If we have detected obvious discrepancies in the Order details, we retain the right, at our sole discretion, to refuse to execute such Order.</p>
	<p>The Draph may execute the Order based on account number only. We may, but are not obliged, to check whether the account number corresponds to the name of the Recipient or the Payer. The Order is deemed executed properly if it has been executed by the indicated account number.</p>
	<p>We will cancel the Order and return received funds to the Payer if we are unable to credit them to the Recipient&rsquo;s Account (e.g., account is closed or account activity is suspended, indicated account number does not exist, there is a negative Account Balance, or else). The Draph may apply a fee for funds return. In this case Payer&rsquo;s refund request should be received.</p>
	<p>If you have noticed that funds were credited to or deducted from the Account by mistake or due to the incorrect Order, then you are obliged to notify us as soon as possible. Until we are duly notified of the mistake, it is considered that the Client gives irrevocable consent for such funds transfer.</p>
	<p>If funds were already credited to the Recipient&rsquo;s Account, we may return the funds to the Payer only upon Recipient&rsquo;s written consent to refund the transaction amount from his/her Account. Payer&rsquo;s refund request should be submitted.</p>
	<p>Prior to executing the Order, we check the Account Balance if there are sufficient funds to execute the Order and deduct applicable fees as agreed in the Fee schedule. If the amount of available funds to execute the Order is lower than the amount of the Payment Transaction (fees included), the Order is automatically refused. The information about payment refusal is made available to you in the Profile.</p>
	<p>If the Draph is entitled for a fee, but the Account Balance is negative, the Client unconditionally commits to immediately upload the Account Balance for the insufficient amount upon our first request.</p>
	<p>Till the Account Balance is insufficient to cover all fees due and payable to the Draph, we reserve the right to suspend provision of Services and Account activity. We reserve the right, without Client&rsquo;s prior consent, to withdraw fees amount due and payable to us from any funds topped up to the Client&rsquo;s Account (s).</p>
	<p>We reserve the right to apply interest on any late payment due to the Draph to the amount of <em>0.05%</em> from the late payment amount for each day of delay. We are entitled to withdraw interest amount from the funds in the Account (s) without prior Client&rsquo;s consent.</p>
	<p>If the Order was executed incorrectly due to the Draph&rsquo;s fall, we will refund the amount of the incorrectly executed Payment Transaction. We will also reimburse the Client all fees applied by us, bank or payment service provider for Payment Transaction execution.</p>
	<p>If funds transferred from the Account under the submitted Order are returned due to reasons beyond our control, then the returned amount shall be credited to the Account. Fees paid by the Payer for the Order execution are not returned, other fees related to the returning of funds may be deducted from the Account.</p>
	<p>Orders submitted shall be formulated clearly and unambiguously, shall be executable, and contain the clearly stated will of the Client. We do not bear responsibility for errors, discrepancies, repetitions and/or contradictions in the submitted Orders, including but not limited to, the correctness of the details of the Order submitted by the Client. If the Order submitted does not contain enough data or contains deficiencies, we can refuse to execute such Order, or can execute it in accordance with the data provided in the Order.</p>
	<p>If due to reasonable grounds we refuse execution of the Order, then we shall immediately notify you thereof, except when such notification is technically impossible or forbidden by applicable laws and regulations.</p>
	<p>In no event we shall execute the Order if funds on the Account are under arrest or the Client&rsquo;s rights to dispose of the funds are otherwise legally limited, suspended, or restricted.</p>
	<p>The Order may not be withdrawn by the Client once it has been submitted and authorised via the System and after the date on which it is deemed irrevocable.</p>
	<p>We reserve the right to record and store Orders submitted and to record and store information about all Payment Transactions performed by the Clients for the period of <em>10 (ten) years</em>, which may be extended, if we are required to do so by the applicable law. Records mentioned in the present clause may be submitted to the Client and/or third persons, who have the right to receive such data in accordance with the applicable laws and regulations, as evidence confirming the submission of the Order and/or execution of Payment Transaction.</p>
	<ol start="7">
		<li>
			<h2><span><strong>ORDER AUTHORISATION AND THE SYSTEM</strong></span></h2>
		</li>
	</ol>
	<p>Each Order before execution must be authorized by the Client in the System. The Client may use the System via the Website.</p>
	<p>To authorize the Order, you should use your personalized credentials and follow our instructions provided in the System.</p>
	<p>We usually request two factor authentication (2FA), consisting, at least, of your personalized password and login.</p>
	<p>If you fail to authorise the Order, the Order will be cancelled.</p>
	<p>For claims concerning Unauthorised Order you are kindly advised to contact us via the communication channels provided in the Terms as soon as possible after becoming aware of the anomaly and within <em>13 (thirteen) months</em> of the date the Unauthorised Order is submitted.</p>
	<p><span>After validation of the legitimacy and investigation of your request regarding an Unauthorised Order, we shall not later than </span><span><em>within 5 (five) Business Days</em></span><span> after validation of the legitimacy and investigation of the request, arrange a refund of the amount of the Unauthorised Order and restore the Account to the amount it would contain if the disputed transaction had never been executed, including any related fees.</span></p>
	<p>In the event of loss or theft of your credentials (login and password), or where you have failed to keep your security information safe, you may be responsible for Unauthorised Order carried out before notification of the dispute.<span style="font-family: Verdana, serif;"><span style="font-size: small;">&nbsp;</span></span></p>
	<p>Except where you have not acted fraudulently, you will not be responsible for any losses incurred in respect of Unauthorised Order arising after you have notified us of the loss, theft, misappropriation or authorized use of your credentials.</p>
	<p>We do not guarantee uninterrupted System or App operation, because System or App operation may be affected (disordered) by many factors beyond our control. We shall put all efforts to secure as fluent System and App operation as possible, however, we shall not be liable for consequences originating due to the System&rsquo;s or App&rsquo;s operation disorders if such disorders occur not due to our fault.</p>
	<p>You irrevocably agree that the System, Website and App shall be used &lsquo;<strong>as it is</strong>&rsquo; and we shall not be liable, and is free from losses or remuneration responsibility if any errors or other technical interruptions occur due to the fault of any third party or other events beyond our control.</p>
	<p>In case of System, Website or App planned technical maintenance, we will inform you in advance, specifying expected operational interruption term. In case of unforeseen technical errors, we may inform you <em>post factum</em>, at the same time, within our capacity and acting in a good faith, trying to renew the functionality of the System, Website or App as soon as possible.</p>
	<p>Without prejudice to the other provisions set forth hereof, we reserve the right to immediately suspend or withdraw your access to the System (both via the Website and App) and Account(s), including execution of Payment Transactions, if we reasonably believe that:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>there may be a breach of the security of the System, </span></p>
		</li>
		<li>
			<p><span>we suspect unauthorized or fraudulent use of the System, Profile or Account (s),</span></p>
		</li>
		<li>
			<p><span>we are required to do so to protect our or our clients&rsquo; legitimate interests.</span></p>
		</li>
		<li>
			<p><span>we are required to do so by law.</span></p>
		</li>
	</ol>
	<p>We will notify you in advance of your access limitation, except where this is not possible or prohibited by the law, then, we will inform you at the earliest opportunity. We will reinstate your access to the System or issue you a new credentials without undue delay when reasons for access limitation cease to exist.</p>
	<p>You must always keep your personalized access credentials - login and password - safe and never disclose it to third parties.</p>
	<p>It is recommended for the Client to keep personalized access credentials safe by following the recommendations below:</p>
	<ul>
		<li>
			<p><span>change the password regularly and ensure that it is not reused across other online accounts,</span></p>
		</li>
		<li>
			<p><span>log off from the System and close the App every time you are not using it,</span></p>
		</li>
		<li>
			<p><span>complete carefully all steps of SCA while logging into the System and authorising the Order,</span></p>
		</li>
		<li>
			<p><span>do not let anyone access your Profile or watch you accessing it,</span></p>
		</li>
		<li>
			<p><span>do not use any functionality that allows login details or passwords to be stored by the computer/mobile or browser the Client is using or to be cached or otherwise recorded,</span></p>
		</li>
		<li>
			<p><span>keep e-mail account related to the System secure,</span></p>
		</li>
		<li>
			<p><span>inform our Customer Support team of Draph if anyone on Draph&rsquo;s or somebody else behalf asks for System access credentials. </span></p>
		</li>
	</ul>
	<p>You may reset the System access password using your e-mail address and phone number. The Client must immediately inform our Customer Support team if the e-mail address or phone becomes compromised, phone has been stolen, etc.</p>
	<p>If you suspect that your personalized access credentials are being lost, used without your authorization, stolen, or otherwise compromised, you must contact us immediately, change password and login. Any undue delays in notifying us may affect the security of your Account (s) and funds held there, and result in the Client&rsquo;s responsibility for incurred financial losses.</p>
	<p>You are not allowed to authorize third parties to access the System and Account (s) using your personalized access credentials, to submit and authorise Orders on your behalf, except in case of the Representative.</p>
	<p>If you do not follow our security instructions, you shall be solely responsible for funds loss and other losses and damage incurred due to security instructions violation or ignorance.</p>
	<ol start="8">
		<li>
			<h2><span><strong>FEES</strong></span></h2>
		</li>
	</ol>
	<p>For the Services provided the Client undertakes to pay fees established by the Draph. Fee rates depend on the kind of Services used by the Client. Fee schedule is available in the Profile.</p>
	<p>By confirming these Terms, the Client confirms that had read and became familiar with the Fee schedule and agrees with them.</p>
	<p>You irrevocably agree that we are entitled to unilaterally increase the applicable fees with a prior written notice:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>at least 60 (sixty) calendar days prior notice to the Client holding a Personal Account with us, and </span></p>
		</li>
		<li>
			<p><span>at least 30 (thirty) calendar days prior notice to the Client holding a Business Account with us.</span></p>
		</li>
	</ol>
	<p>If you do not agree with the newly established fees, you may terminate Business Relationship and provision of Services, and close your Account (s) in order provided herein below.</p>
	<p>We are entitled, at our sole discretion, to decrease fees or cancel fees without prior notification obligation.</p>
	<p>You should regularly visit our Website, App and your Profile to follow any news and amendments in respect of the Fee schedule and Services provided.</p>
	<p>We reserve the right, at our sole discretion, to apply individual fees which may differ from the standard Fee schedule. Individual fee rates will be set based on reasonable and justified grounds determined by us and depending on particular Services used by the Client. The Client will be notified in advance via the Profile on any individual fee rates applicable to the Client. If the Client does not agree to the individual fee rates, the Client is entitled not to use the Services in relation to which individual fee rates are set or to terminate Business Relationship and close the Account (s). Standard Fee schedule shall apply to those Services which are not covered by individual fees.</p>
	<p>The Client irrevocable agrees and confirms that Account opening fee and Account maintenance fee, if any, are paid in advance and not refundable.</p>
	<p>Fees should be payable in the Account currency and will be deducted automatically from the Account without your prior notification and consent. If Account currency varies from the fee currency, the Draph is entitled to apply currency exchange under the currency exchange rates established by the Draph and valid at the moment of currency exchange. Currency exchange fee will be deducted from the Account.</p>
	<p>If you hold several Accounts with us, we reserve the rights to deduct fees from any Account having sufficient Account Balance.</p>
	<p>If a fee is expressed in a percentage, we retain the right to apply a particular fee up to the highest extent designated and vice-versa, with no additional Client&rsquo;s notification. All fees deducted are reflected in the Account statement available free of charge in the Profile.</p>
	<ol start="9">
		<li>
			<h2><span><strong>TERMINATION OF SERVICES AND CLOSING THE ACCOUNT</strong></span></h2>
		</li>
	</ol>
	<p>You may terminate our cooperation and provision of Services at any time by notifying us.</p>
	<p>By terminating the Business Relationship established between us, we will suspend provision of all Services, close all your Accounts and cancel your access to the System and App.</p>
	<p>You must ensure that on Business Relationship termination date your obligations towards us are fully met (including, but not limited to, any fees and charges due and payable under the Fee schedule or other agreements between you and us in respect of the Services provided, as well as any penalties, damages, losses and other amounts that we have suffered or paid to third parties due to the Client&rsquo;s fault).</p>
	<p>You must ensure sufficient Account Balance in the Account (s) at the cooperation termination date to cover all fees due and payable to us for the Services provided in accordance with the Fee schedule or other agreements entered between you and us. Our fees shall be charged <em>pro rata</em>.</p>
	<p>You shall ensure that at the cooperation termination date there are no funds in the Account (s), except those necessary to pay any outstanding fees or charges.</p>
	<p>We will not be able to terminate our cooperation and close the Account (s), if there are funds in the Account (s).</p>
	<p>We will not be able to terminate our cooperation if at termination date there are any outstanding obligations of any nature, including any outstanding fees and charges, towards us.</p>
	<p>When you receive a confirmation about the Account (s) closure you will no longer have access to the Profile and Account (s).</p>
	<p>You may not terminate our cooperation and close the Account (s) in case of any investigation of fraud, money laundering, indebtedness and the like, conducted by us or any competent authority. We reserve the right to &lsquo;<em>freeze</em>&rsquo; your funds and suspend provision of the Services until the investigation is fully completed.</p>
	<p>We are entitled immediately to terminate provision of Services and close the Account (s), if we reasonably believe that:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>you are in breach of any undertaking and warranty set forth hereof, and such breach is not remediable or was not remedied within a timeframe determined by us,</span></p>
		</li>
		<li>
			<p><span>you fail to complete necessary identity verification checks and KYC procedures, or to submit the information required by us for (a) AML/CTF purposes; (b) other legal purposes reasonably necessary to provide the Services; (c) to protect our legitimate interests; or (d) information provided by you do not conform to the requirements stipulated by the applicable laws and regulations or doubts concerning the veracity and authenticity of submitted documents arise,</span></p>
		</li>
		<li>
			<p><span>it becomes known from the reliable source or we have reason to believe that you are involved in any fraudulent activity, money laundering, terrorism financing or other criminal activity,</span></p>
		</li>
		<li>
			<p><span>we reasonably suspect that Account (s) has been used or is being used without your authorization or fraudulently used by third persons,</span></p>
		</li>
		<li>
			<p><span>it becomes known about the Client's indebtedness, bankruptcy or liquidation,</span></p>
		</li>
		<li>
			<p><span>we ought to terminate provision of the Services and close the Account (s) due to mandatory binding regulatory requirements,</span></p>
		</li>
		<li>
			<p><span>type of business activity/occupation or transactional activity of the Client has or may have the potential harm to our financial stability or reputation, or may harm the justified interests of other clients, third parties and financial marke</span>t<span> in whole.</span></p>
		</li>
	</ol>
	<p>We will inform you on our decision to terminate our cooperation and close the Account (s) as soon as reasonably practicable by sending respective notice via the System, giving instructions regarding funds held on the Account (s) withdrawal, except when a <span>provision of such notification would weaken safety measures or is forbidden by the applicable law</span>.</p>
	<p><span>We retain the right to terminate our cooperation and close the Account (s) if you do not use the Services and your Account is inactive for more than a consequent </span><span><em>6 (six) months</em></span><span> period. A respective termination notice via the System will be sent to you </span><span><em>2 (two) months</em></span><span> in advance. </span>We will ask you to withdraw the funds in the Account within a reasonable period, during which the Account will be accessible for withdrawing the remaining balance only. <span>For the avoidance of doubts, deduction of fees, charges or other payments due and payable to us under these Terms shall not be treated as a Payment Transaction or Account activity. </span></p>
	<p>If on the cooperation termination date, the Account still holds a balance, after all fees, charges and other fair payments are deducted, we retain the right to close the Account and block the remaining balance. You will be able to withdraw any remaining funds from the closed Account only by contacting us and requesting funds to be transferred from the closed Account. We will not earn any interest while such funds are deposited with us. Additional fee may be applied for such late funds&rsquo; withdrawal.</p>
	<p>Without prejudice to the other provisions set forth hereof, no fees already paid or paid in advance for Account opening and Services provided are reimbursable to the Client in case of Business Relationship termination and Account closure for any reason.</p>
	<p>We guarantee the Client&rsquo;s data storage, including transactions history, for a minimum period of <em>10 (ten) years</em> since the Business Relationship is terminated and Account (s) is/are closed. If you need to get access to your data after the termination of our cooperation, you need to contact us. Client&rsquo;s identity verification will be conducted. If the Client has been successfully identified, limited access to the System with &lsquo;<em>read only</em>&rsquo; option will be granted to the Client and the Client will be able to review data stored with us, create and download Account statement. Fee for Client&rsquo;s repeat identification and access to the System renewal may be applied. We reserve the right to apply full KYC procedure to the Client if we reasonably consider it necessary.</p>
	<ol start="10">
		<li>
			<h2><span><strong>PERSONAL DATA AND CONFIDENTIALITY</strong></span></h2>
		</li>
	</ol>
	<p>Processing of your Personal Data is governed by our Privacy Policy available on the Website.&nbsp;</p>
	<p>Will use and process Personal Data collected and held with us, provided by your or third parties mainly to execute our undertakings while providing the Services, ensure qualitative Services, to perform our regulatory obligations and protect legitimate interests. Purposes and legal grounds of Personal Data processing are detailed in our Privacy Policy available on the Website.</p>
	<p>You should carefully read our Privacy Policy before establishing a Business Relationship with us.</p>
	<p>By applying to our Services and registering in the System, you confirm that you have become familiar with the Privacy Policy.</p>
	<p>In our day-to-day operations we strictly follow and comply with the terms of the Privacy Policy, the Act and the GDPR. We guarantee security of your Personal Data being at our disposal and your Personal Data legitimate processing. We guarantee that Personal Data will be used to the minimum extent necessary to perform our contractual and/or regulatory obligations laid down hereof and any supplements herein.</p>
	<p>You agree that Draph may transfer your, Representative&rsquo;s, directors&rsquo;, shareholders&rsquo;, beneficial owners&rsquo; and etc., if any, Personal Data to partners (data processors), banks, other payment service providers and other third parties to ensure provision of Services and execution of Orders, and to meet our regulatory obligations.</p>
	<p>By agreeing to these Terms, you grant us irrevocable and unconditional right to cooperate with third party service providers, including but not limited to, submitting requests and checking various databases, in order to verify the identity of the Client and the accuracy of other data and documents submitted by the Client for the KYC purposes, and with the aim to meet regulatory requirements mandatory applicable to us and protect our legal interests.</p>
	<p>If your Personal Data is to be transferred outside the Czech Republic or the European Union area to the third countries, we will get your explicit consent first and provide all security measures applied while transferring and holding your data and list of third countries and third-party services providers to which your Personal Data will be transferred.</p>
	<p>The Parties undertake to protect each other&rsquo;s technological and commercial information which has become known to them.</p>
	<p>The Client undertakes not to disclose and keep harmless our technological and commercial information from unauthorized disclosure to third parties without allowed purpose. In each case when the Client is intending or is obliged to disclose any confidential information regarding the Draph and/or Services provided, the Client shall inform us in advance. In case prior notification is not possible, then the Client shall inform us as soon as possible.</p>
	<ol start="11">
		<li>
			<h2><strong>AMENDMENTS</strong><span><strong> TO THE TERMS</strong></span></h2>
		</li>
	</ol>
	<p>We are entitled to unilaterally amend these Terms.</p>
	<p>We shall inform you in advance before any amendments (incl. supplements) to these Terms or any other document we refer to in them, coming into force. Notification will be sent via the System:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>for a Client holding a Personal Account - 60 (sixty) calendar days in advance,</span></p>
		</li>
		<li>
			<p>for a Client<span> holding a Business Account - 30 (thirty) calendar days in advance.</span></p>
		</li>
	</ol>
	<p>If you disagree with any amendments, you may within amendments notification period specified herein above terminate the Business Relationship by sending a respective notification via the System.</p>
	<p>If within the amendments notification period the Client has not objected to amendments made, it shall be deemed that you agree to them and have accepted them as binding since the amendments effective date.</p>
	<p>We are entitled to unilaterally amend the present Terms with immediate effect in case of changes in laws and regulations mandatory applicable to us or regulating provision of the Services and requiring amendments to be made with immediate effect.</p>
	<p>You may ask us to provide a draft of any proposed amendments to the Terms, Fee schedule, other supplement hereto or any document we refer to in them prior to its effective date and ask to provide respective consultation on new or amended terms and conditions.</p>
	<p>Any amendments, supplements or documents we refer to in the Terms shall be deemed to be an integral part of these Terms and shall have the same legal power as the Terms.</p>
	<p>All historical and new versions of the Terms shall be available in the System. It is the Client&rsquo;s duty to check the Website and the Profile on a regular basis, at least <em>once a month</em>, for any updates performed by us.</p>
	<p>You may not unilaterally amend the Terms or the Fee schedule.</p>
	
	<ol start="12">
		<li>
			<h2><span><strong>COMMUNICATION, COMPLAINTS AND DISPUTES</strong></span></h2>
		</li>
	</ol>
	<p>Parties will communicate via the System or Client&rsquo;s e-mail registered in the System.</p>
	<p>The Client must always maintain a valid e-mail address registered with us and should check the Profile for incoming messages regularly.</p>
	<p>We are not liable for any loss or damage suffered by the Client if the Client did not specify their contacts (e-mail, mobile number, etc.) properly or did not update them in a timely manner.</p>
	<p>You can contact us by e-mail address <a href="mailto:support@swipez.eu"><span style="color: #0000ff;"><u>support@swipez.eu</u></span></a><span style="color: #0000ff;"><u><strong>.</strong></u></span></p>
	<p>We are entitled to send you notices via the post service. In this case, we shall use the postal address specified by the Client in the System and it shall be deemed that the Client has received the notice within <em>5 (five) Business Days</em> after it was sent, unless the Client actually receives the notification later and this fact may be evidenced by the Client.</p>
	<p>It shall be deemed that messages (claims, requests, or complaints) from the Clients are received on the day of their submission to us, if this day is a Business Day. Messages submitted outside the Business Day shall be deemed received on the next Business Day.</p>
	<p>If you have any complaints about the Services provided, as the first instance Customer Support team should be contacted at&nbsp;<span style="color: #0000ff;"><u>support@swipez.eu</u></span> or leaving a message in the Profile.</p>
	<p>If issue is not resolved to Client&rsquo;s satisfaction by our Customer Support team, the Client has a right to submit a formal complaint by sending an email at&nbsp;<a href="mailto:info@swipez.eu"><span style="color: #1155cc;"><u>info@swipez.eu</u></span></a>.</p>
	<p>We shall send you complaint acknowledgement to the same e-mail from which complaint was received or via the Profile within 48 (forty-eight) hours of receiving a complaint.</p>
	<p><a name="Bookmark"></a> We will consider Client&rsquo;s written complaint and respond to it in writing within 15 (fifteen) Business Days since the day of receipt of a relevant request. In any event, we will send our final response within 35 (thirty-five) Business Days since your complaint is received. Any complaints shall be considered free of charge.</p>
	<p class="western"><a name="Bookmark1"></a><span style="font-family: Calibri, serif;"><span>If our response to your complaint does not satisfy the Client or no answer has been provided thereto, you may apply to the supervisory authority - the Czech National Bank</span></span> <span style="font-family: Calibri, serif;"><span>by e-mail </span></span><a href="mailto:podatelna@cnb.cz"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span><u>podatelna@cnb.cz</u></span></span></span></a><span style="font-family: Calibri, serif;"><span>, by post to Senov&aacute;žn&aacute; 3, 115 03 Praha 1, or to the data box &ndash; data box ID: 8tgaiej, or using electronic link </span></span><a href="https://www.cnb.cz/en/public/contacts/electronic-form/"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span><u>https://www.cnb.cz/en/public/contacts/electronic-form/</u></span></span></span></a><span style="font-family: Calibri, serif;"><span>. </span></span></p>
	<p class="western"><span style="font-family: Calibri, serif;"><span>You may also apply to the Public Defender of Rights (Ombudsman), </span></span><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span><u><a href="http://www.ochrance.cz/">www.ochrance.cz/</a>, </u></span></span></span><span style="font-family: Calibri, serif;"><span>&Uacute;doln&iacute; 39, 602&nbsp;00 Brno, e-mail address </span></span><a href="mailto:podatelna@ochrance.cz"><span style="color: #0000ff;"><span style="font-family: Calibri, serif;"><span style="font-size: small;"><u>podatelna@ochrance.cz</u></span></span></span></a><span style="font-family: Calibri, serif;"><span style="font-size: small;">, data box ID: </span></span><span><span style="font-size: small;">jz5adky, </span></span><span><span style="font-size: small;">(+420) 542 542&nbsp;888.</span></span></p>
	<p>A detailed guidance for filing and processing Clients&rsquo; complaints could be found on the Website.</p>
	<ol start="14">
		<li>
			<h2><span><strong>FINAL PROVISIONS</strong></span></h2>
		</li>
	</ol>
	<p>The Terms, any supplement hereto or any document we refer to in them constitute an entire mutual agreement between the Parties. If any of the provisions of the Terms is held invalid or unenforceable, it shall be deemed deleted and will not invalidate the other provisions.</p>
	<p>If one or more of the provisions of the Terms lapse or are declared as such under any law or regulation or following a final decision of a court of competent jurisdiction, the other provisions will retain their binding force and scope. The provisions considered null and invalid would then be replaced by provisions closest in scope and meaning to those initially agreed.</p>
	<p>The Terms shall come into force after the Client gives the consent to them by clicking on the validation tab when registering for Account opening with the System. The Terms shall remain in force for an indefinite period of time till terminated subject to the provisions set forth hereof.</p>
	<p>The Terms cannot be subject to a complete or partial transfer by the Client, whether against payment or free of charge. It is therefore forbidden to transfer to a third party any rights or obligations that the Client owns hereunder, except Client&rsquo;s Representative. In the event of a breach of this condition in addition to the immediate termination hereof the Client may be held liable by the Draph.</p>
	<p>&nbsp;We may assign our rights and obligations under the Terms to another person at any time, by giving prior written notice to the Client:</p>
	<ol type="i" class="litle">
		<li>
			<p><span>for a Client holding a Personal Account &ndash; at least </span><span><em>60 (sixty) calendar days</em></span><span> in advance,</span></p>
		</li>
		<li>
			<p>for a<span> Client holding a Business Account &ndash; at least </span><span><em>30 (thirty) calendar days</em></span><span> in advance.</span></p>
		</li>
	</ol>
	<p>In case of assignment your rights under the Terms will not be affected.</p>
	<p>The Terms shall be governed by the legislation of the Czech Republic. Any dispute arising out of or in connection with the Terms shall be referred to the relevant court of the Czech Republic.</p>
	<p>The Parties confirm that they are entitled to execute these Terms, are aware of the terms and conditions laid down herein and Parties contractual duties, are entitled to undertake obligations and guarantees provided herein, and enter Business Relationship voluntarily.</p>
	<p>&nbsp;</p>
<p >Last updated: 01.10.2024</p>	
</DocumentLayout>
</template>

<script setup lang="ts">
// Импорты из Vue 3 Composition API

import { DocumentLayout } from "@/layout";
import { ref, computed, defineProps, defineEmits, onMounted, watch } from "vue";
// import HomeAdapterService from "./home/adapter/HomeAdapterService";

// const adapter = new HomeAdapterService();

onMounted(async () => { });
const onShowAnswer = (id: string) => { };
</script>

<style lang="less" scoped>
.h1--hero {
	width: 100%;
}

.global--padding {
	p {
		color: #fff
	}

	ul {
		li::marker {
			color: #fff;
			font-weight: bold;
		}
	}

	h2,
	h3 {
		padding-top: 1em;
		padding-bottom: .5em;
		color: #fff;
		text-align: left !important;

	}

	ol {
		margin-left: 20px;

		li::marker {
			color: #fff;
			font-weight: bold;
			font-size: 3em;
			font-family: Made Outer Sans, sans-serif;
		}

		&.litle li::marker {
			font-size: 14px;

		}
	}
}
</style>

import { createApp, nextTick } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from '@/locale-en'; // объект с переводами
import ru from '@/locale-ru'; // объект с переводами
import ch from '@/locale-ch'; // объект с переводами

async function addExternalScript(src: string) {
	// debugger
  const script = document.createElement("script");
	script.src = src;
	script.defer = true;
	document.head.appendChild(script);
}
// await addExternalScript(
//   "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js"
// );
// await addExternalScript("webflow.js");
const i18n = createI18n({
  locale: 'en', // начальная локаль
  legacy: false,
  fallbackLocale: 'en', // Язык для подстановки, если перевод не найден
  messages: {
    en,ru, ch
  },
});

createApp(App).use(router).use(i18n).mount('#app')


<template>
  <div data-w-id="448c0df2-8fda-b42f-7c4e-044c21a776a2" class="contact">
    <div id="contact" class="anchor is--footer"></div>
    <div class="w-layout-hflex global--padding">
      <div class="global--wrapper is--footer">
        <div class="footer--header">
          <h2 class="h--large is--white v--2">NEED OUR <br />TEAM HELP?</h2>
          <div class="v-sep">
            <div class="inner--line--right"></div>
          </div>
        </div>
        <div class="footer-contacts--wrapper">
          <div class="w-layout-vflex contact--wrapper">
            <div class="text--chip is--yellow">CALL</div>
            <a href="tel:+420773645226" class="contact--link w-inline-block">
              <div>+420 773 645 226</div>
              <div class="link--underline">
                <div class="link--inner--lightline"></div>
              </div>
            </a>
          </div>
          <div class="w-layout-vflex contact--wrapper">
            <div class="text--chip is--yellow">EMAIL</div>
            <a
              href="mailto:support@swipez.eu"
              class="contact--link w-inline-block"
            >
              <div>support@swipez.eu</div>
              <div class="link--underline">
                <div class="link--inner--lightline"></div>
              </div>
            </a>
          </div>
          <div  style="max-width:220px; display: flex; align-items: center; padding-top:20px">
            <div style="width: 80px">
              <img src="@/assets/img/card-visa.png" />
            </div>
            <div style="width: 80px">
              <img src="@/assets/img/card-master.png" />
            </div>
          </div>
        </div>

        <div class="copy">
          <div class="w-layout-hflex disclaimer">
            <div class="copy-p">
              Copyright © 2024 Draph Solutions s.r.o. <br />All Rights Reserved
            </div>
            <div class="div-block-7">
              <a
                href="/privacy-policy"
                target="_blank"
                class="footer--link w-inline-block"
              >
                <div>Privacy Policy</div>
              </a>
              <a
                href="/terms-and-conditions"
                target="_blank"
                class="footer--link w-inline-block"
              >
                <div>Terms and Conditions</div>
              </a>
              <a
                href="/complaints-disclaimer"
                class="footer--link w-inline-block"
              >
                <div>Complaints Disclaimer</div>
              </a>
              <a
                href="/safeguarding-disclaimer"
                class="footer--link w-inline-block"
              >
                <div>Safeguarding Disclaimer</div>
              </a>
              <!-- <a href="/cryptocurrency-exchange-notice" class="footer--link w-inline-block">
								<div>Cryptocurrency Exchange Notice</div>
							</a> -->
            </div>
          </div>
          <div class="w-layout-hflex footer--nav">
            <div class="disclaimer--p">
              All rights reserved. All other trademarks, product names and logos
              identified or mentioned herein are the property of their
              respective owners. While using this website and/or our services
              you agree to have carefully read, accepted and became legally
              bound by the Terms and Conditions , Complaints Policy , Privacy
              Policy. The operator of this website is Draph Solutions s.r.o.,
              with its headquarters in Pýchavková 264/2, Pitkovice, 10400 Praha,
              Czech Republic, Czech-based financial institution, licensed and
              regulated by the Czech National Bank, legally auth for provision
              of personal and business current accounts and for provision of
              domestic and international payments. Licence granted by the Czech
              National Bank pursuant to Act No. 370/2017 Coll. Payment Services
              Act.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.footer-contacts--wrapper{

	flex-wrap: wrap;
}</style>
